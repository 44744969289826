




















































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { SwiperOptions } from 'swiper'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { BadgeProps } from '../../../../dsl/atoms/Badge'
import { CarouselProps } from '../../../../dsl/molecules/Carousel'
import { ImageProps } from '../../../../dsl/atoms/Image'

import CloseIcon from '../../icons/CloseIcon.vue'
import { generateImagePlaceholder } from '../../support/image-placeholder'

import { CustomImageZoom, CustomThumbnails } from './partials'
import { defaultSwiperConfig } from './partials/thumbnails/Thumbnails.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Gallery>({
  name: 'Gallery',
  components: {
    CloseIcon,
    CustomThumbnails,
    CustomImageZoom
  }
})
export class Gallery extends Vue {
  @Prop({
    required: false,
    type: Array
  })
  public readonly badges!: BadgeProps[]

  @Prop({
    required: true,
    type: Array
  })
  public readonly fullImages!: ImageProps[]

  @Prop({
    required: true,
    type: Object
  })
  public readonly mobileCarousel!: CarouselProps

  @Prop({
    required: true,
    type: Array
  })
  public readonly previewImages!: ImageProps[]

  @Prop({
    required: true,
    type: Array
  })
  public readonly thumbnails!: ImageProps[]

  @Prop({
    required: false,
    type: Object,
    default: defaultSwiperConfig
  })
  public readonly thumbnailsSwiperConfiguration!: SwiperOptions

  @Inject({
    from: IS_MOBILE_PROVIDER_KEY,
    default: () => defaultProvider<boolean>(false)
  })
  public readonly isMobile!: () => boolean

  public isActiveFullScreenMobile: boolean = false
  public selectedImageIndex: number = 0

  public get fullImage () {
    return this.fullImages[this.selectedImageIndex]
  }

  public activateFullScreenMobile () {
    this.isActiveFullScreenMobile = true
  }

  public deactivateFullScreenMobile () {
    this.isActiveFullScreenMobile = false
  }

  public onThumbnailChange (index: number) {
    this.selectedImageIndex = index
  }

  public onSlideChange (activeSlideIndex: number) {
    this.selectedImageIndex = activeSlideIndex
  }

  public onSlideClick () {
    this.activateFullScreenMobile()
  }

  public get fallbackImage () {
    return generateImagePlaceholder('small').src
  }

  @Watch('isActiveFullScreenMobile')
  public onActiveFullScreenMobileChange (newValue: boolean) {
    if (this.isMobile()) {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }
  }
}

export default Gallery
