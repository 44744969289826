





























































































































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventPayload,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'

import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  ProductSneakPeakData,
  Variant
} from '../../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { ImageProps } from '../../../../../dsl/atoms/Image'
import { BadgeShape } from '../../../../../dsl/atoms/Badge'

import { DrawerType, IDrawer } from '../../../../shared/contracts/services'
import { openAuthDrawer, UserModel } from '../../../../auth/shared'
import { ProductCartMixin } from '../../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../../shared'
import { ToastType } from '../../../../shared/services'
import { toImageProps } from '../../../../shared/support'

import {
  FavouriteProductsServiceType,
  IFavouriteProductsService
} from '../../../contracts/services'
import { Modals } from '../../../config/modals'
import {
  translateProductVariantsToVariantsSwitch,
  VariantsSwitch,
  VariantsSwitchProps
} from '../../../molecules/VariantsSwitch'

import { attributesIconsRegistry } from '../ProductCard.config'
import { AttributesParser } from '../partials/AttributesParser.vue'
import { isAttribute, translateProductToProductCard } from '../ProductCard.helpers'
import { ProductCardVariant } from '../ProductCard.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductCardRich>({
  name: 'ProductCardRich',
  components: { AttributesParser, VariantsSwitch },
  created (): void {
    this.setActiveVariant(Object.keys(this.variants)[0])
  },
  mounted (): void {
    this.checkIsFavourite()
    /**
     * @inheritDoc
     */
    this.initComponentAttributes()

    if (this.shouldAddToCart) {
      this.eventBus.handle('app:cart.remove', (event: EventPayload<AnyObject>) => {
        if (event.payload) {
          if (event.payload.items[0].id === this.activeVariant?.sku) {
            this.itemAdded = false
          }
        }
      })
    }
  }
})
export class ProductCardRich extends Mixins<ProductCartMixin>(ProductCartMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isGratis!: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData | ProductSneakPeakData

  @Prop({ type: Array, required: true })
  public disabledBadgeIcons!: string[]

  /**
   * TODO: Set default as true
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasButton!: boolean

  @Prop({ type: Array, required: false })
  public readonly mainAttributes!: string[]

  @Prop({ type: Array, required: false })
  public readonly additionalAttributes!: string[]

  @Prop({ type: String, required: false, default: 'medium' })
  public readonly modalSize?: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly useDrawer?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAddToCart?: boolean

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null

  /**
   * Determines whether variant is in favourites
   */
  public isFavourite: boolean = false

  public isFavouriteLoading: boolean = false

  public isLoading: boolean = false

  public mainAttributesCollection: string[] = []

  public additionalAttributesCollection: string[] = []

  public itemAdded: boolean = false

  public get massDetails (): string[] {
    if (!this.activeVariant) {
      return []
    }

    const details = []
    if (this.activeVariant.attributes.volumeName) {
      details.push('volumeName')
    }

    if (this.activeVariant.attributes.weightName) {
      details.push('weightName')
    }

    return details
  }

  public get mainAttributesItems (): string[] {
    return this.mainAttributesCollection
  }

  public get additionalAttributesItems (): string[] {
    return this.additionalAttributesCollection
  }

  public get wasItemAdded (): boolean {
    return this.itemAdded
  }

  /**
   * Description shortened to specific amount of characters
   */
  public get shortenedName (): string {
    const MAX_DESC_LENGTH = 30

    if (!this.activeVariant) {
      return ''
    }

    if (this.activeVariant.name.length) {
      if (this.activeVariant.name.length < MAX_DESC_LENGTH) {
        return this.activeVariant.name
      }

      return `${this.activeVariant.name.substring(0, MAX_DESC_LENGTH)}...`
    }

    return ''
  }

  /**
   * Determines whether product has discount.
   */
  public get hasDiscount (): boolean {
    if (!this.activeVariant) {
      return false
    }

    return this.activeVariant.price.regularPrice > this.activeVariant.price.finalPrice
  }

  public get hasSellableQuantity (): boolean {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return false
    }

    return this.activeVariant.sellableQuantity > 0
  }

  /**
   * Determines whether product has variants.
   */
  public get hasVariants (): boolean {
    return this.variants.length > 0
  }

  /**
   * Define count of available variants
   */
  public get variantsCount (): number {
    return this.variants.length
  }

  /**
   * Product's image.
   */
  public get productImage (): ImageProps | undefined {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return
    }

    const activeVariantHasImages = Array.isArray(this.activeVariant.images) && this.activeVariant.images.length > 0
    if (activeVariantHasImages) {
      return this.activeVariant.images[0]
    }

    const variantWithImages = Object.values(this.product.variants).find((variant) => {
      return variant.images.length > 0
    }) as Variant<string>

    if (variantWithImages) {
      return toImageProps(variantWithImages.images[0])
    }

    return { src: '', alt: '' }
  }

  public get productLine (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.ProductLine)
  }

  /**
   * Determines whether component has everything to be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasVariants && typeof this.activeVariant !== 'undefined'
  }

  /**
   * Translated (mapped) variants.
   */
  public translateProductVariantsToVariantsSwitch (type = 'color'): VariantsSwitchProps['variants'] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  /**
   * Determines product variants.
   */
  public get variants (): Variant<string>[] {
    return Object.values(this.product.variants)
  }

  /**
   * Return attributes with proper data based on AllowedAttribute type
   */
  public getAttributesData (attributes: string[]) {
    const attr = []

    if (!attributes || attributes.length === 0) {
      return
    }

    const iconsRegistry: Record<string, string> = Object.entries(attributesIconsRegistry)
      .reduce((acc, [key, value]) => {
        const disabledIcon = this.disabledBadgeIcons.find((icon) => icon === key)
        if (!disabledIcon) {
          return { ...acc, [key]: value }
        }

        return acc
      }, {})

    for (const value of Object.values(attributes)) {
      /**
       * Check if attribute should have been rendered as icon
       */
      const icon = iconsRegistry[value]
      if (icon) {
        attr.push({
          key: value,
          label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
          icon,
          shape: BadgeShape.Square,
          driver: 'icon'
        })
      } else {
        if (value === 'isSale' && this.activeVariant) {
          attr.push({
            key: value,
            label: `-${100 - (Math.round((this.activeVariant.price.finalPrice / this.activeVariant.price.regularPrice) * 100))}%`,
            shape: BadgeShape.Square,
            driver: 'badge'
          })
        } else {
          attr.push({
            key: value,
            label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
            shape: BadgeShape.Square,
            driver: 'badge'
          })
        }
      }
    }

    return attr
  }

  /**
   * Handles adding product to cart.
   */
  public async onAddToCart (disableAction: boolean = false): Promise<void> {
    if (!this.cartService) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isLoading = true
    this.itemAdded = true

    try {
      await this.addToCart(
        { ...this.activeVariant, description: this.productLine },
        1,
        true,
        this.modalSize,
        this.isMobile() ? this.useDrawer : false,
        disableAction
      )
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Opens add review modal
   */
  public openProductReviewsModal (): void {
    if (!this.modalConnector) {
      return
    }

    let color = ''
    if (this.activeVariant && this.activeVariant?.identifier.type === 'color') {
      color = this.activeVariant.identifier.value
    }

    this.modalConnector.open(Modals.ProductReviewsModal, {
      color: color,
      description: this.activeVariant?.name,
      location: this.activeVariant?.link,
      rate: this.activeVariant?.rating,
      sku: this.activeVariant?.sku,
      title: this.activeVariant?.attributes.productLine,
      variants: this.translateProductVariantsToVariantsSwitch('color')
    })
  }

  /**
   * Handles update:model of variant switchers.
   */
  public onVariantSwitchUpdate (slug: string): void {
    this.setActiveVariant(slug)
  }

  /**
   * Sets the active variant.
   */
  public setActiveVariant (slug: string): void {
    this.activeVariant = translateProductToProductCard(this.product).variants[slug]
    this.checkIsFavourite()
  }

  /**
   * Changes (toggles) is favourite state of current variant
   */
  public async toggleFavourite (): Promise<void> {
    if (!this.favouriteProductsService) {
      return
    }

    // If there is no user, open auth drawer.
    if (this.authService && !this.authService.check() && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector)
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavouriteLoading = true
    try {
      if (!this.isFavourite) {
        await this.favouriteProductsService.addToFavourite(this.activeVariant.sku)
        this.isFavourite = true
      } else {
        await this.favouriteProductsService.removeFromFavourite(this.activeVariant.sku)
        this.isFavourite = false
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isFavouriteLoading = false
    }
  }

  /**
   * Checks the current variant if it is present in favourites list.
   */
  protected async checkIsFavourite (): Promise<void> {
    if (
      (this.authService && !this.authService.check()) ||
      !this.favouriteProductsService
    ) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavouriteLoading = false
    this.isFavourite = false

    try {
      this.isFavourite = await this.favouriteProductsService.check(this.activeVariant.sku)
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isFavouriteLoading = false
    }
  }

  /**
   * Gets the attribute by key
   *
   * @param attribute - attribute key
   */
  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.activeVariant.attributes
      ? this.activeVariant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, level: ToastType): void {
    this.showToast(message, level)
  }

  /**
   * Set specific attributes for ProductCard from config
   * @param mainAttributes
   * @param additionalAttributes
   * @protected
   */
  protected setAttributeTypes (mainAttributes: string[], additionalAttributes: string[]): void {
    const main: string[] = []
    const additional: string[] = []

    if (!this.activeVariant) {
      this.mainAttributesCollection = main
      this.additionalAttributesCollection = additional
      return
    }

    for (const [attrKey, attrValue] of Object.entries(this.activeVariant.attributes)) {
      if (mainAttributes) {
        for (const value of Object.values(mainAttributes)) {
          if (value === attrKey) {
            if (value === attrKey && attrValue === true) {
              main.push(String(attrKey))
            }
          }
        }
      }

      if (additionalAttributes) {
        for (const value of Object.values(additionalAttributes)) {
          if (value === attrKey) {
            if (value === attrKey && attrValue === true) {
              additional.push(String(attrValue))
            }
          }
        }
      }
    }

    /** Add isSale attribute regarding current promotional price */
    if (!this.activeVariant.attributes.isSale && this.activeVariant.price.finalPrice < this.activeVariant.price.regularPrice) {
      main.push(AllowedAttributes.IsSale)
    }

    this.mainAttributesCollection = main
    this.additionalAttributesCollection = additional
  }

  /**
   * Init component's allowed attributes
   * @protected
   */
  protected initComponentAttributes (): void {
    this.setAttributeTypes(this.mainAttributes, this.additionalAttributes)
  }

  private get brand (): string | undefined {
    if (typeof this.getAttribute<string>(AllowedAttributes.Brand) === 'undefined') {
      return
    }

    return this.getAttribute<string>(AllowedAttributes.Brand)
  }
}

export default ProductCardRich
