import gql from 'graphql-tag'

import { MagentoVersion, QueryDefinition } from '../../../../contexts'
import { DocumentNode } from 'graphql'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const placeOrderV1: DocumentNode = gql`
  mutation placeOrder($cartId: String!) {
      placeOrder(
          input: {
              cart_id: $cartId
          }
      ) {
          order {
              order_number
              przelewy24PaymentUrl
              payment_method
          }
      }
  }`

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const placeOrderV2: DocumentNode = gql`
  mutation placeOrder($cartId: String!) {
    placeOrder(
      input: {
        cart_id: $cartId
      }
    ) {
      order {
        order_number
        instructions
        payment_method
        payu_redirect_uri
        paynow_redirect_uri
      }
    }
  }`

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PLACE_ORDER_QUERY_VERSIONS: Record<MagentoVersion, DocumentNode> = {
  [MagentoVersion.Ver24]: placeOrderV1,
  [MagentoVersion.Default]: placeOrderV2
}
