









































import { Component, Mixins } from 'vue-property-decorator'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import { Loader } from '../../../shared/molecules/Loader'
import { ProductCard } from '../../../products/organisms/ProductCard'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'

import { CartItem } from '../../molecules/CartItem'
import { CartItemProps } from '../../molecules/CartItem/CartItem.contracts'
import { Inject } from '../../../../support'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AddedToCartDrawer>({
  name: 'AddedToCartDrawer',
  components: {
    CartItem,
    Loader,
    ProductCard
  },
  created () {
    /**
     * @inheritDoc
     */
    this.loadSuggestedProducts(this.payload.product.sku, RecommendationAction.AddToCart)
  },
  mounted () {
    this.eventBus.handle('app:mobile-nav-click', (event: EventPayload<string>) => {
      if (event.payload) {
        this.close()
      }
    })

    this.setAppropriateDrawerHeight()
  }
})
export class AddedToCartDrawer extends Mixins(AbstractDrawer, SuggestedProductsMixin) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public windowHeight: number | null = null

  public get productItem (): CartItemProps {
    return {
      description: this.payload.product.title ?? '',
      price: this.payload.product.price,
      thumbnail: this.payload.product.image,
      title: this.payload.product.brand ?? '',
      enhancedDesc: `${this.payload.product.category ?? ''} ${this.payload.product.volume ?? ''}`,
      properties: [],
      isDisabled: true
    }
  }

  /**
   * Redirect user to cart page.
   */
  public async goToCart (): Promise<void> {
    await this.$router.push({ name: 'checkout.cart' })
    this.close()
  }

  protected setAppropriateDrawerHeight (): void {
    const drawer: HTMLDivElement | null = document.querySelector('.drawer')
    const drawerContent: HTMLDivElement | null = document.querySelector('.drawer .content')

    if (drawer && drawerContent && this.payload.drawerHeight) {
      drawerContent.style.setProperty('max-height', `${this.payload.drawerHeight}px`)
      drawerContent.style.setProperty('bottom', `${this.payload.mobileNavHeight ? this.payload.mobileNavHeight : 0}px`)
      drawer.style.setProperty('max-height', `${this.payload.drawerHeight}px`)
      drawer.style.setProperty('overflow-x', 'hidden')
      drawer.style.setProperty('bottom', `${this.payload.mobileNavHeight ? this.payload.mobileNavHeight : 0}px`)
    }
  }
}

export default AddedToCartDrawer
