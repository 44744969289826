






































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ClockIcon from '../icons/Clock.vue'
import InfoRoundIcon from '../icons/InfoRoundIcon.vue'
import MoneyOffIcon from '../icons/MoneyOffIcon.vue'
import MoneyIcon from '../icons/MoneyIcon.vue'
import PinIcon from '../icons/PinIcon.vue'
import { InPostParcel } from '../InPostModal.contracts'

import { InPostHours } from './InPostHours.vue'

@Component({
  name: 'ParcelDetails',
  components: {
    ClockIcon,
    InfoRoundIcon,
    InPostHours,
    MoneyIcon,
    MoneyOffIcon,
    PinIcon
  }
})
export class ParcelDetails extends Vue {
  @Prop({ type: Object, required: true })
  public readonly parcel!: InPostParcel

  public goBack (): void {
    this.$emit('back')
  }

  public onSelection (): void {
    this.$emit('selected', this.parcel)
  }
}

export default ParcelDetails
