













import { Component, Prop, Vue } from 'vue-property-decorator'

import { benefitLogo, MILES_PER_PLN } from '../../shared'
import { BenefitProgram } from '../../contracts/programs'

import { MilesAndMoreCounterProps } from './MilesAndMoreCounter.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<MilesAndMoreCounter>({
  name: 'BenefitProgramPreview'
})
export class MilesAndMoreCounter extends Vue {
  @Prop({ type: String, required: false })
  public readonly text?: MilesAndMoreCounterProps['text']

  @Prop({ type: Number, required: true })
  public readonly total?: MilesAndMoreCounterProps['total']

  public readonly logo: string = benefitLogo[BenefitProgram.MilesAndMore]

  public count: number = this.total ? Math.floor(this.total * MILES_PER_PLN) : 0
  public label: string =
    this.text ?? this.$t('front.loyalty.molecules.MilesAndMoreCounter.text').toString()
}

export default MilesAndMoreCounter
