

















































































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  IModal,
  ModalType
} from '@movecloser/front-core'
import {
  Component,
  Inject as VueInject,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch
} from 'vue-property-decorator'

import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  ProductSneakPeakData
} from '../../../../../contexts'
import { BadgeProps } from '../../../../../dsl/atoms/Badge'

import {
  DrawerType,
  IDrawer
} from '../../../../shared/contracts/services'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { StructureConfigurable } from '../../../../../support/mixins'
import { Modals } from '../../../config/modals'

import { ImageProps } from '../../../../../dsl/atoms/Image'
import { ShapeMap, SizeMap } from '../../../../../dsl/composables'

import { ToastType } from '../../../../shared/services'
import { ToastMixin } from '../../../../shared'
import { openAuthDrawer, UserModel } from '../../../../auth/shared'
import { ProductCartMixin } from '../../../../checkout/shared/mixins/product-cart.mixin'

import {
  translateProductToVariantsSwitch,
  translateProductVariantsToVariantsSwitch,
  VariantsSwitch,
  VariantsSwitchProps
} from '../../../molecules/VariantsSwitch'
import {
  FavouriteProductsServiceType,
  IFavouriteProductsService
} from '../../../contracts/services'

import { isAttribute, translateProductToProductCard } from '../ProductCard.helpers'
import { ProductCardVariant } from '../ProductCard.contracts'
import { ProductCardConfig } from '../ProductCard.config'
import { Application } from '../../ProductHeader'
import {
  BaseWishListMixin,
  IBaseWishListMixin
} from '../../../../wishlist/shared/mixins/base.mixin'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ProductCardDefault>({
  name: 'ProductCardDefault',
  components: { VariantsSwitch },
  created (): void {
    this.setActiveVariant(Object.keys(this.variants)[0])
  },

  mounted (): void {
    this.checkIsFavourite()
    this.calculateVariantSwitchPositionY()
  }
})
export class ProductCardDefault extends Mixins<
    IBaseWishListMixin,
    ProductCartMixin,
    ToastMixin,
    StructureConfigurable>(
      BaseWishListMixin,
      ProductCartMixin,
      ToastMixin,
      StructureConfigurable
    ) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  public configuration!: ProductCardConfig

  @Prop({ type: Boolean, required: false, default: false })
  public isGratis!: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData | ProductSneakPeakData

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Ref('variantSwitchRef')
  public variantSwitchRef!: Vue

  // FIXME!: By default only display color VariantSwitcher in ProductCard
  //         if changes will be required move this logic into config.
  public readonly DEFAULT_IDENTIFIER = 'color'

  public config: AnyObject = { ...this.configuration }

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null

  /**
   * Determines whether variant is in favourites
   */
  public isFavourite: boolean = false

  public isFavouriteLoading: boolean = false

  public isLoading: boolean = false

  public variantSwitchPositionY = '-90px'

  /**
   * Determines the application of current product
   */
  protected get applicationOptions (): string[] | null {
    const application: string | undefined = this.getAttribute<string>(AllowedAttributes.Application)

    if (typeof application === 'undefined') {
      return null
    }

    if (application.includes('/')) {
      return application.split('/')
    }

    return [application]
  }

  /**
   * Determines (constructs) variant badges.
   */
  public get badges (): BadgeProps[] {
    const badges: BadgeProps[] = []

    // TODO: Note! Should depend on API params
    // if (!this.isFinalPriceDifferent) {
    //   badges.push({
    //     label: this.$t('front.products.organisms.productCard.attributes.isNew').toString(),
    //     theme: 'default',
    //     shape: ShapeMap.Rectangle,
    //     variant: VariantMap.Full,
    //     size: SizeMap.Medium
    //   })
    // }

    // IsSale has higher priority
    if (this.isFinalPriceDifferent) {
      if (this.isSale) {
        badges.push({
          label: this.$t('front.products.organisms.productCard.attributes.isSale').toString(),
          theme: this.badgeTheme,
          shape: ShapeMap.Rectangle,
          variant: this.badgeVariant,
          size: SizeMap.Medium
        })
      } else {
        badges.push({
          label: this.$t('front.products.organisms.productCard.attributes.isPromotion').toString(),
          theme: this.badgeTheme,
          shape: ShapeMap.Rectangle,
          variant: this.badgeVariant,
          size: SizeMap.Medium
        })
      }
    } else if (this.isNew) {
      badges.push({
        label: this.$t('front.products.organisms.productCard.attributes.isNew').toString(),
        theme: this.themeOfNewBadge,
        shape: ShapeMap.Rectangle,
        variant: this.badgeVariant,
        size: SizeMap.Medium
      })
    }

    return badges
  }

  public get badgeVariant (): string {
    return this.getConfigProperty('badgeVariant')
  }

  public get badgeTheme (): string {
    return this.getConfigProperty('badgeTheme')
  }

  public get buttonTheme (): string {
    return this.getConfigProperty('buttonTheme')
  }

  /**
   * Determines button variant.
   */
  public get buttonVariant (): string {
    return this.getConfigProperty('buttonVariant')
  }

  /**
   * Determines the whether final price is different.
   */
  public get isFinalPriceDifferent (): boolean {
    return this.hasDiscount
  }

  /**
   * Determines whether **VARIANT** is new.
   */
  private get isNew (): boolean {
    if (typeof this.getAttribute<boolean>(AllowedAttributes.IsNew) === 'undefined') {
      return false
    }

    return Boolean(this.getAttribute<boolean>(AllowedAttributes.IsNew))
  }

  /**
   * Determines whether **VARIANT** is on sale.
   */
  private get isSale (): boolean {
    if ((typeof this.getAttribute<boolean>(AllowedAttributes.IsSale) === 'undefined' ||
      !this.getAttribute<boolean>(AllowedAttributes.IsSale)) && !this.hasDiscount) {
      return false
    }

    return true
  }

  /**
   * Determines whether product has discount.
   */
  public get hasDiscount (): boolean {
    if (!this.activeVariant) {
      return false
    }

    return this.activeVariant.price.regularPrice > this.activeVariant.price.finalPrice
  }

  /**
   * Determines whether product has more that one variant.
   */
  public get hasMoreVariants (): boolean {
    if (!this.product.variantSelector || !(this.DEFAULT_IDENTIFIER in this.product.variantSelector)) {
      return false
    }

    return Object.keys(this.product.variantSelector[this.DEFAULT_IDENTIFIER]).length > 1
  }

  public get hasSellableQuantity (): boolean {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return false
    }

    if (typeof this.activeVariant.sellableQuantity !== 'number') {
      return false
    }

    return this.activeVariant.sellableQuantity > 0
  }

  /**
   * Determines whether product has variants.
   */
  public get hasVariants (): boolean {
    return Object.keys(this.variants).length > 0
  }

  /**
   * Product's image.
   */
  public get productImage (): ImageProps | undefined {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return
    }

    if (Array.isArray(this.activeVariant.images) && this.activeVariant.images.length > 0) {
      return this.activeVariant.images[0]
    }

    return { src: '', alt: '' }
  }

  public get themeOfNewBadge (): string {
    return this.getConfigProperty('themeOfNewBadge')
  }

  public calculateVariantSwitchPositionY () {
    const productCardVariantsSwitchEl = this.$refs.variantSwitchRef as Vue
    if (!productCardVariantsSwitchEl) {
      return
    }
    const productCardVariantsSwitchHeight = productCardVariantsSwitchEl.$el.getBoundingClientRect().height

    this.variantSwitchPositionY = `${-productCardVariantsSwitchHeight}px`
  }

  /**
   * Change content position on mouse over for products with variants.
   * @param htmlDivElement
   */
  public animateContentOnMouseOver (htmlDivElement?: HTMLDivElement) {
    const productCardVariantsSwitchEl = this.$refs.variantSwitchRef as Vue

    if (!htmlDivElement || !productCardVariantsSwitchEl || !productCardVariantsSwitchEl || !this.hasVariants) {
      return
    }

    const productCardVariantsSwitchHeight = productCardVariantsSwitchEl.$el.getBoundingClientRect().height

    htmlDivElement.style.transform = `translateY(${-productCardVariantsSwitchHeight}px)`
  }

  /**
   * Check content position on mouse leave for products with variants.
   * @param htmlDivElement
   */
  public animateContentOnMouseLeave (htmlDivElement?: HTMLDivElement) {
    if (!htmlDivElement || !this.hasVariants) {
      return
    }

    htmlDivElement.style.transform = 'translateY(0px)'
  }

  /**
   *
   * TODO: Should be operated by key, not by text.
   * Gets the application of the active variant.
   */
  public getApplication (application: string): undefined | string {
    const day = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.day}`)
    const night = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.night}`)
    const dayNight = this.$t(`front.products.organisms.productHeader.attributes.application.${Application['day/night']}`)
    switch (application) {
      case day:
        return 'DayIcon'
      case night:
        return 'NightIcon'
      case dayNight:
        return 'DayNightIcon'
    }
  }

  public get productLine (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.ProductLine)
  }

  /**
   * Determines whether component has everything to be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasVariants && typeof this.activeVariant !== 'undefined'
  }

  /**
   * Translated (mapped) variants.
   */
  public translateProductVariantsToVariantsSwitch (type = 'color'):
    VariantsSwitchProps['variants'] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  /**
   * Determines product variants.
   */
  public get variants (): ProductData['variants'] {
    return this.product.variants
  }

  public get variantsSwitchProps () {
    return translateProductToVariantsSwitch(this.product, 'color')
  }

  /**
   * Handles adding product to cart.
   */
  public async onAddToCart (): Promise<void> {
    if (!this.cartService) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isLoading = true

    try {
      await this.addToCart({ ...this.activeVariant, description: this.productLine })
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Opens add review modal
   */
  public openProductReviewsModal (): void {
    if (!this.modalConnector) {
      return
    }

    let color = ''
    if (this.activeVariant && this.activeVariant?.identifier.type === 'color') {
      color = this.activeVariant.identifier.value
    }

    this.modalConnector.open(Modals.ProductReviewsModal, {
      color: color,
      description: this.activeVariant?.name,
      location: this.product.urlPath + '?sku=' + this.activeVariant?.sku,
      rate: this.activeVariant?.rating,
      sku: this.activeVariant?.sku,
      title: this.activeVariant?.attributes.productLine,
      variants: this.translateProductVariantsToVariantsSwitch('color')
    })
  }

  /**
   * Handles update:model of variant switchers.
   */
  public onVariantSwitchUpdate (slug: string): void {
    this.setActiveVariant(slug)
  }

  /**
   * Sets the active variant.
   * @param sku - sku of the product.
   */
  public setActiveVariant (slug: string): void {
    this.activeVariant = translateProductToProductCard(this.product).variants[slug]

    this.checkIsFavourite()
  }

  /**
   * Changes (toggles) is favourite state of current variant
   */
  public async toggleFavourite (): Promise<void> {
    if (!this.isWaitingForAuth && !this.authService?.check() && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector)
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavouriteLoading = true
    try {
      if (!this.isFavourite) {
        await this.add({
          quantity: 1,
          sku: this.activeVariant.sku
        })
        this.isFavourite = true
      } else {
        await this.remove(this.activeVariant.sku)
        this.isFavourite = false
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isFavouriteLoading = false
    }
  }

  /**
   * Checks the current variant if it is present in favourites list.
   */
  protected async checkIsFavourite (): Promise<void> {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavourite = this.isInWishlist(this.activeVariant.sku)
  }

  /**
   * Gets the attribute by key
   *
   * @param attribute - attribute key
   */
  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.activeVariant.attributes
      ? this.activeVariant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, level: ToastType): void {
    this.showToast(message, level)
  }

  @Watch('wishlist')
  private onWishlist (): void {
    if (this.wishlist) {
      this.checkIsFavourite()
    }
  }
}

export default ProductCardDefault
