
















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import {
  AttributeDriver
} from '../ProductCard.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AttributesParser>({
  name: 'AttributesParser'
})
export class AttributesParser extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: false, default: 'badge' })
  public driver!: string

  @Prop({ type: String, required: false, default: '' })
  public label!: string

  @Prop({ type: String, required: false, default: '' })
  public icon!: string

  @Prop({ type: String, required: false, default: '' })
  public value!: string

  @Prop({ type: String, required: false, default: '' })
  public shape!: string

  /**
   * Available attributes drivers
   */
  public driverType = AttributeDriver
}

export default AttributesParser
