import { VueConstructor } from 'vue'

import { AllowedAttributes } from '../../../../contexts'

import ProductCardDefault from './versions/ProductCardDefault.vue'
import ProductCardRich from './versions/ProductCardRich.vue'
import { VariantMap } from '../../../../dsl/composables'

export enum AttributeDriver {
  Icon = 'icon',
  Badge = 'badge'
}

export enum ProductCardType {
  Default = 'default',
  Rich = 'rich'
}

/**
 * Registry with AllowedAttributes that should be rendered as icon
 * TODO: Choose proper icons
 */
export const attributesIconsRegistry: Record<string, string> = {
  [AllowedAttributes.Application]: 'DayNightIcon',
  [AllowedAttributes.CertFSC]: 'ArrowLeftIcon',
  [AllowedAttributes.CertVegan]: 'ArrowDownIcon',
  [AllowedAttributes.CertCrueltyFree]: 'ArrowTopIcon',
  [AllowedAttributes.IsNew]: 'ArrowTopIcon',
  [AllowedAttributes.IsNatural]: 'Leaf'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const productCardTypeRegistry: Record<string, VueConstructor> = {
  [ProductCardType.Default]: ProductCardDefault,
  [ProductCardType.Rich]: ProductCardRich
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PRODUCT_CARD_COMPONENT_KEY = 'ProductCard'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface ProductCardConfig {
  additionalAttributes: string[]
  badgeTheme: string
  badgeVariant: string
  buttonTheme: string
  buttonVariant: string
  mainAttributes: string[]
  modalSize: string
  productCardType: string
  useDrawer: boolean
}

/**
 * Component configuration for app
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const PRODUCT_CARD_DEFAULT_CONFIG = {
  additionalAttributes: ['gift', 'sponsored'],
  badgeTheme: '',
  badgeVariant: VariantMap.Full,
  buttonTheme: 'primary',
  buttonVariant: 'outline',
  disabledBadgeIcons: [],
  mainAttributes: ['isNew', 'isBestseller', 'isSale'],
  modalSize: 'medium',
  productCardType: 'default',
  useDrawer: false,
  themeOfNewBadge: 'flat'
}
