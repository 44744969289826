













import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { ProductData, ProductSneakPeakData } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import {
  PRODUCT_CARD_COMPONENT_KEY, PRODUCT_CARD_DEFAULT_CONFIG,
  ProductCardConfig,
  productCardTypeRegistry
} from './ProductCard.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductCard>({
  name: 'ProductCard',
  created () {
    this.config = this.getComponentConfig(
      PRODUCT_CARD_COMPONENT_KEY,
      { ...PRODUCT_CARD_DEFAULT_CONFIG }
    )
  }
})
export class ProductCard extends Mixins(Vue, StructureConfigurable) {
  @Prop({ type: Object, required: true })
  public readonly product!: ProductData | ProductSneakPeakData

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAddToCart?: boolean

  public config!: ProductCardConfig

  public get configuration (): string | undefined {
    return this.config.productCardType
  }

  public get mainAttributesCollection (): string[] {
    return this.getConfigProperty<Array<string>>('mainAttributes')
  }

  public get additionalAttributesCollection (): string[] {
    return this.getConfigProperty<Array<string>>('additionalAttributes')
  }

  public get disabledBadgeIcons (): string[] {
    return this.getConfigProperty<Array<string>>('disabledBadgeIcons')
  }

  public get modalSize (): string {
    return this.getConfigProperty<string>('modalSize')
  }

  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  /**
   * Get component type based on config
   */
  public get productCardComponent (): VueConstructor {
    return productCardTypeRegistry[this.getConfigProperty<string>('productCardType')]
  }

  protected detectedIfGift (sku: string): boolean {
    const keywords = ['gratis', 'free', 'prezent']

    if (!sku) {
      return false
    }

    const toTest: string = sku.toLowerCase()

    for (const k of keywords) {
      if (toTest.indexOf(k) >= 0) return true
    }

    return false
  }

  public get isGratis (): boolean {
    // @ts-expect-error - Product does not have sku
    return this.detectedIfGift(this.product.sku)
  }
}

export default ProductCard
