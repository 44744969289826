































































import { Component, Mixins } from 'vue-property-decorator'
import { FiltersConfig, Filter } from '@movecloser/front-core/lib/contracts/filter-parser'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import { AccordionItem } from '../../../../dsl/atoms/Accordion'

import { FilterBadges } from '../FilterBadges'
import { FilterList } from '../FilterList'
import { FilterListProps } from '../FilterList/FilterList.contracts'
import { FilterParamConfig, NavigationItem } from '../../../../contexts'
import { AnyObject } from '@movecloser/front-core'
import { StructureConfigurable } from '../../../../support/mixins'
import {
  FILTERS_DRAWER_COMPONENT_CONFIG_MAP,
  FILTERS_DRAWER_COMPONENT_KEY
} from './FiltersDrawer.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<FiltersDrawer>({
  name: 'FiltersDrawer',
  components: { FilterBadges },
  created () {
    this.initSelectedFilters()
    this.initShownCategoryNavItems()
    this.config = this.getComponentConfig(
      FILTERS_DRAWER_COMPONENT_KEY,
      FILTERS_DRAWER_COMPONENT_CONFIG_MAP
    )
  }
})
export class FiltersDrawer extends Mixins(AbstractDrawer, StructureConfigurable) {
  public filters: FiltersConfig = this.payload.filters

  public isExpanded: boolean = false
  public listControlsVisible: boolean = true
  public selected: AnyObject = {}
  public shownCategoryNav: NavigationItem[] = []
  public SHOWN_CATEGORY_NAV_ITEMS = 6

  public get hasCategoryTitle (): boolean {
    return this.getConfigProperty<boolean>('hasCategoryTitle')
  }

  public get shouldRenderEmptyState (): boolean {
    return this.getConfigProperty<boolean>('shouldRenderEmptyState')
  }

  public get accordionItems (): AccordionItem[] {
    return this.payload.params.map((item: FilterParamConfig) => {
      const props: FilterListProps = {
        isMulti: item.isMulti,
        items: item.options,
        filter: this.filters[item.queryParam],
        queryParam: item.queryParam
      }

      return {
        id: item.queryParam,
        label: Object.keys(this.selected)
          .includes(item.queryParam) && this.selected[item.queryParam] !== null
          ? `${item.label} (${this.selected[item.queryParam]})` : item.label,
        content: {
          component: FilterList,
          props,
          on: {
            'update:filter': (value: Filter) => {
              this.updateFilter(item.queryParam, value)
              if (this.hasInstantApply) {
                this.applyFilters()
              }
            }
          }
        },
        classname: [Object.keys(this.selected).includes(item.queryParam) && this.selected[item.queryParam] !== null ? '--hasIdentifier' : '', item.isOpen ? '--always-open' : '']
      }
    })
  }

  public get areControlsVisible (): boolean {
    return this.listControlsVisible
  }

  public get isListExpanded (): boolean {
    return this.isExpanded
  }

  public get drawerTitle (): string | null {
    return this.payload.title
  }

  public get navData (): NavigationItem[] | null {
    return this.payload.navData
  }

  public get shownCategoryNavItems (): NavigationItem[] {
    return this.shownCategoryNav
  }

  public get badgeParams (): FilterParamConfig[] {
    if (!this.payload.hideListControlParamsOnMobile) {
      return this.payload.params
    }

    return (this.payload.params as FilterParamConfig[]).filter(p => !!p.isAttribute)
  }

  public get hasInstantApply (): boolean {
    return this.getConfigProperty<boolean>('hasInstantApply')
  }

  public applyFilters (): void {
    this.payload.onApply(this.filters)
    this.close()
  }

  /**
   * Expand list with category nav links
   */
  public expandCategoryNav (): void {
    this.isExpanded = true

    if (this.navData && this.navData.length > 0) {
      this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  /**
   * Close list with category nav links
   */
  public closeCategoryNav (): void {
    this.isExpanded = false

    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  public onRemoveFilter (queryParam: string, value: string) {
    this.filters = this.payload.removeFilter(this.filters, this.payload.params, queryParam, value)
    if (this.hasInstantApply) {
      this.applyFilters()
    }
  }

  public onClearFilters () {
    this.filters = this.payload.clearFilters(this.payload.params, this.filters)
    if (this.hasInstantApply) {
      this.applyFilters()
    }
  }

  public updateFilter (param: string, value: Filter) {
    this.filters = { ...this.filters, [param]: value }
    this.selected = {
      ...this.selected,
      [param]: this.countSelected(this.filters[param])
    }
  }

  protected countSelected (value: Filter): number | null {
    return Array.isArray(value) && value.length > 0 ? value.length : null
  }

  protected initSelectedFilters (): void {
    for (const [key, value] of Object.entries(this.filters)) {
      this.selected = {
        ...this.selected,
        [key]: this.countSelected(value)
      }
    }
  }

  /**
   * Compose initially shown category nav links
   * @protected
   */
  protected initShownCategoryNavItems (): void {
    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

        /**
         * Hide list controls visibility
         */
        this.listControlsVisible = false
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }
}

export default FiltersDrawer
