

























































/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { MilesAndMoreCounter } from '../../../../loyalty/molecules/MilesAndMoreCounter'
import { Price } from '../../../../../contexts'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'

@Component<Advanced>({
  name: 'Advanced',
  components: {
    MilesAndMoreCounter
  }
})
export class Advanced extends Vue {
  @PropSync('model', {
    required: false,
    default: null
  })
  public _model!: VariantsSwitchProps['model']

  @Prop({
    type: Array,
    required: false
  })
  public variants?: Variant[]

  /**
   * Get variants with bestRatio identifier
   */
  public get bestLabelVariants () {
    const withRatio = []

    if (!this.variants) {
      return
    }

    for (const variant of Object.values(this.variants)) {
      if (!variant.price) {
        return
      }

      withRatio.push({
        identifierLabel: variant.identifierLabel,
        ratio: (variant.price.finalPrice / Number(String(variant.identifierLabel).split('-')[0])).toFixed(2)
      })
    }

    const bestRatioVariant = withRatio.sort((a, b) => Number(a.ratio) < Number(b.ratio) ? -1 : 1)

    return this.variants.map((item) => {
      return {
        ...item,
        hasBestRatio: item.identifierLabel === bestRatioVariant[0].identifierLabel
      }
    })
  }

  public calculateDiscount (price: Price): string {
    return `-${100 - (Math.round((price.finalPrice / price.regularPrice) * 100))}%`
  }

  protected onModelChange (slug: string): void {
    if (this._model === slug) {
      return
    }

    this._model = slug
  }
}

export default Advanced
