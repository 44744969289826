






























import { Component, Mixins } from 'vue-property-decorator'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import { IToastMixin, ToastMixin } from '../../../shared'
import { Inject } from '../../../../support/plugins/front-core'

import {
  IProductsRepository,
  ProductsRepositoryType
} from '../../../products/contracts/repositories'

import { BaseCartMixin, IBaseCart } from '../../shared/mixins/base-cart.mixin'
import { translateToCartItem } from '../../molecules/CartItem/CartItem.helpers'
import { RouteName } from '../../routes'

import { SingleCartItem } from '../SingleCartItem'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { CartItemData, ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CartDrawer>({
  name: 'CartDrawer',
  components: { SingleCartItem }
})
export class CartDrawer extends Mixins<IBaseCart, AbstractDrawer, IToastMixin>(
  BaseCartMixin,
  AbstractDrawer,
  ToastMixin
) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public isLoading: boolean = true

  public async goToCart (): Promise<void> {
    if (this.$route.name === `checkout.${RouteName.Cart}`) {
      this.close()
      return
    }

    await this.$router.push({ name: `checkout.${RouteName.Cart}` })
    this.close()
  }

  /**
   * Handles quantity changing of particular product.
   */
  public async onQuantityChange (uid: string, newQuantity: number): Promise<void> {
    try {
      const updatedCart = await this.cartService.updateCartItem(this.cartId, uid, newQuantity)
      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      this.eventBus.emit(
        'app:cart.view',
        {
          currency: updatedCart.total.currency,
          value: updatedCart.total.value,
          coupon: (updatedCart.coupons[0]) ? updatedCart.coupons[0].code : '',
          items: updatedCart.items.map((item) => {
            const variant = getFirstVariant(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              name: variant.name,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              currency: updatedCart.total.currency,
              variant: variant.sku,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: item.product.variants && (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )

      this.refreshCart(updatedCart)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  /**
   * Handles @removeFromCartCb of SingleCartItem organism.
   */
  public async removeFromCart (uid: string): Promise<void> {
    try {
      const itemsToRemove = this.cart?.items.filter(item => {
        return item.uid === uid
      })
      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      const getFirstVariantKey = (item: CartItemData) => {
        const key = Object.keys(item.product.variants)[0] ?? ''
        return (key !== '_') ? key : ''
      }

      const updatedCart = await this.cartService.removeFromCart(this.cartId, uid)

      if (itemsToRemove && itemsToRemove.length > 0) {
        const itemToRemove = itemsToRemove[0]
        const variant = getFirstVariant(itemToRemove)
        const variantSlug = getFirstVariantKey(itemToRemove)

        this.eventBus.emit('app:cart.remove', {
          items: [
            {
              id: variant.sku,
              parent_id: itemToRemove.product.sku,
              name: variant.name,
              variant: variantSlug,
              currency: this.cart?.total.currency,
              price: variant.price.finalPrice,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              quantity: itemToRemove.quantity,
              url:
                this.siteService.getActiveSiteAddress() + `${itemToRemove.product.urlPath}`,
              image: variant.images?.length ? this.siteService.getActiveSiteAddress() + variant.images[0].url
                : ''
            }
          ],
          value: variant.price.finalPrice
        })
      }

      this.refreshCart(updatedCart)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  public translateToCartItem = translateToCartItem
}

export default CartDrawer
