

























import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Navigation, Pagination, Swiper, SwiperOptions } from 'swiper'

import { ImageProps } from '../../../../../../dsl/atoms/Image'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../../support'

import { Direction } from './Thumbnails.contrats'
import { defaultSwiperConfig } from './Thumbnails.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Thumbnails>({
  name: 'Thumbnails',
  mounted () {
    setTimeout(() => {
      this.initSwiper()
    }, 200)
  },
  beforeDestroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
})

export class Thumbnails extends Vue {
  @Prop({
    required: false,
    type: String,
    default: () => Direction.Horizontal
  })
  direction!: Direction

  @Prop({
    required: true,
    type: Array
  })
  thumbnails!: ImageProps[]

  @Prop({
    required: false,
    type: Object,
    default: defaultSwiperConfig
  })
  public readonly thumbnailsSwiperConfiguration!: SwiperOptions

  @Prop({
    required: true,
    type: Number
  })
  selectedImageIndex!: number

  /**
   * Determines whether the app is running on a mobile device.
   */
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Ref('swiperContainerRef')
  readonly swiperContainer?: HTMLDivElement

  public swiper: Swiper | null = null

  public get isVertical (): boolean {
    return this.direction === Direction.Vertical
  }

  public get swiperConfig (): SwiperOptions {
    return {
      ...defaultSwiperConfig(this.direction, this.selectedImageIndex),
      ...this.thumbnailsSwiperConfiguration
    }
  }

  public changeSelectedImage (index: number) {
    this.$emit('change', index)
  }

  public slideNext (index: number) {
    if (index < this.thumbnails.length - 1) {
      this.$emit('change', index + 1)
    }
  }

  public slidePrev (index: number) {
    if (index > 0) {
      this.$emit('change', index - 1)
    }
  }

  public initSwiper (): void {
    if (!this.swiperContainer) {
      return
    }

    Swiper.use([Navigation, Pagination])

    this.swiper = new Swiper(this.swiperContainer, this.swiperConfig)
  }

  @Watch('selectedImageIndex')
  protected onSelectedImageIndexChange (newValue: number, oldValue: number) {
    if (!this.swiper || !this.isMobile()) {
      return
    }

    if (newValue > oldValue) {
      this.swiper.slideNext()
    } else {
      this.swiper.slidePrev()
    }
  }
}

export default Thumbnails
